@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-BlackItalic.woff2') format('woff2'), url('fonts/SFProDisplay-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-Bold.woff2') format('woff2'), url('fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'), url('fonts/SFProDisplay-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-LightItalic.woff2') format('woff2'), url('fonts/SFProDisplay-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-Regular.woff2') format('woff2'), url('fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-ThinItalic.woff2') format('woff2'), url('fonts/SFProDisplay-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-Medium.woff2') format('woff2'), url('fonts/SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'), url('fonts/SFProDisplay-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'), url('fonts/SFProDisplay-UltralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
body {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  color: #858a8c;
  font-size: 24px;
  line-height: 1.2;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2 {
  font-family: 'Bebas Neue';
  color: #000;
}
.banner-section {
  background-image: url('../public/images/banner-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 25px;
  position: relative;
}
.banner-section .banner-container {
  padding: 0 40px;
}
.banner-section .head-icon {
  display: inline-block;
}
.banner-section .banner-cartoon-img {
  position: absolute;
  top: 0;
  left: 0;
}
.banner-section .banner-content {
  max-width: 760px;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
.banner-section .banner-logo {
  text-align: right;
  margin-bottom: 40px;
}
.banner-section .banner-logo img {
  margin-left: auto;
}
.content-detail .banner-title {
  position: relative;
  max-width: 390px;
  text-align: center;
  margin: 0 auto 12px;
  z-index: 1;
}
.content-detail .banner-title h1 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.content-detail .banner-title img {
  max-width: 100%;
}
.content-discriptions {
  text-align: center;
  position: relative;
  top: -24px;
}
.content-discriptions p {
  background: #e9e8e6;
  font-size: 24px;
  line-height: 1.2;
  padding: 10px 5px;
  margin: 0 0 10px;
  box-shadow: 0 0px 9px 0 #00000021;
  color: #858a8c;
}
.content-discriptions p:first-child {
  transform: rotate(-3deg);
}
.content-discriptions p:last-child {
  transform: rotate(-4deg);
}
.banner-enter-text .text-hand-title {
  position: absolute;
  right: 40px;
  text-align: center;
  max-width: 315px;
  top: 118px;
}
.banner-enter-text {
  position: absolute;
  right: 0;
  top: 165px;
  z-index: 1;
}
.morph-section {
  margin-top: 415px;
  position: relative;
}
.morph-section .morph-cards {
  display: flex;
  justify-content: flex-end;
  right: -30px;
  position: relative;
  z-index: 9;
}
.banner-bg-cartoon {
  position: absolute;
  bottom: 0;
  left: -40px;
}
.card-content .card-discriptions {
  max-width: 500px;
  margin: 0;
  padding: 20px 20px;
  border-radius: 10px;
  background: #e9ede6;
  font-size: 20px;
  line-height: 1.2;
}
.card-content .card-discriptions p {
  margin: 0;
  text-align: center;
}
.morph-card-holder .card-image {
  position: relative;
  z-index: 9;
}
.card-content {
  position: relative;
  top: -20px;
}
.morph-section .morph-cards .morph-card-holder:first-child .card-content .card-discriptions:first-child {
  transform: rotate(4deg);
}
.morph-section .morph-cards .morph-card-holder:first-child .card-content .card-discriptions {
  max-width: 420px;
  margin: 0 auto;
}
.morph-section .morph-cards .morph-card-holder:first-child .card-content .card-discriptions:last-child {
  transform: rotate(-4deg);
  position: relative;
  top: -10px;
}
.morph-section .morph-cards .morph-card-holder:last-child .card-content .card-discriptions {
  background: #fdddf6;
}
.morph-section .morph-circle {
  position: absolute;
  right: 314px;
  z-index: 99;
}
.morph-section .morph-cards .morph-card-holder:nth-child(2) {
  padding-top: 120px;
}
.morph-section .morph-title {
  position: absolute;
  left: 338px;
  right: 0;
  max-width: 330px;
  margin: 0 auto;
  top: -35px;
}
.morph-section .morph-circle {
  position: absolute;
  right: 287px;
  z-index: 99;
  top: -56px;
}
section.morph-collection {
  position: relative;
  background: #dcf9f7;
}
.morph-collection-detail {
  position: absolute;
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
}
.morph-collection-detail .morph-collection-circle {
  margin-top: 40px;
}
.morph-collection-detail .morph-collection-circle img {
  max-width: 90%;
}
.morph-collection-detail .morph-collection-bgimg img {
  margin: 0 8% 0px;
}
.dimond-section .dimond-video {
  text-align: center;
  max-width: 830px;
  position: relative;
  margin: 0 auto;
  padding-top: 55px;
}
.dimond-section .dimond-video iframe {
  width: 735px;
  height: 435px;
  margin: 0 auto;
}
.dimond-section .dimond-video .dimond-video-title {
  position: absolute;
  top: 0;
  left: 0;
}
.dimond-section {
  background-image: url('../public/images/slider-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.dimond-section .edouard-section {
  position: relative;
  top: -45px;
}
.edouard-section .edouard-title {
  background: #ffffff5e;
  text-align: center;
  max-width: 490px;
  margin: 0 auto;
  position: relative;
  padding: 6px 18px 0;
}
.edouard-section .edouard-title img {
  margin: 0 auto;
}
.edouard-section .edouard-slider {
  max-width: 499px;
}
.edouard-section .edouard-content .edouard-content-dis {
  background: #e1e1e1;
  padding: 20px;
  border-radius: 15px;
  max-width: 560px;
  margin-left: auto;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  transform: rotate(-3deg);
  margin-right: 15px;
}
.edouard-section .edouard-content .edouard-content-dis p:last-child {
  margin: 0;
}
.edouard-section .edouard-content-slider {
  margin-top: 15px;
}
.edouard-section .collab-section .collab-title {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
}
.edouard-section .collab-section {
  text-align: center;
  position: relative;
  padding: 41px 0;
}
.collab-section .collab-image img {
  margin: 0 auto;
}
.toundery-section {
  text-align: center;
  background-image: url('../public/images/footer-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.toundery-section {
  text-align: center;
  padding: 40px 0;
}
.toundery-section .toundery-title .toundery-heading {
  position: relative;
  top: -13px;
  left: 11px;
}
.toundery-section .toundery-title .toundery-content {
  max-width: 860px;
  margin: 35px auto 0;
}
.toundery-section .toundery-title img {
  margin: 0 auto;
  max-width: 322px;
}
.toundery-section .toundery-title .toundery-heading img {
  max-width: 290px;
}
.toundery-section .toundery-list ul {
  display: flex;
}
.toundery-section .toundery-list {
  margin-top: 30px;
  margin-bottom: 50px;
}
.list-detail .list-content {
  max-width: 314px;
  margin: 0 10% 0;
}
.faq-section {
  position: relative;
}
.faq-section .faq-title img {
  margin: 0 auto;
}
.faq-section .faq-title {
  margin-bottom: 40px;
}
.faq-content .faq-content-detail h3 {
  text-transform: uppercase;
  color: #626262;
  font-weight: 600;
  font-size: 30px;
  font-family: 'Bebas Neue';
  letter-spacing: 1.3px;
}
.faq-content .faq-content-detail {
  margin-bottom: 40px;
}
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-content ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.footer-content ul li {
  margin: 0 5px;
}
.faq-section .faq-content {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
.faq-section .faq-cartoon-img-two {
  position: absolute;
  right: 0;
  bottom: -40px;
}
.faq-cartoon-img-one {
  position: absolute;
  left: 0;
  top: 0;
}
.collab-section .collab-title {
  position: absolute;
  left: 0;
  right: 0;
  top: -42px;
}
.collab-section .collab-title img {
  margin: 0 auto;
}
.collab-section {
  position: relative;
  padding: 10px 0px 40px;
}
.edouard-section .edouard-slider .slick-slider .slick-prev,
.edouard-section .edouard-slider .slick-slider .slick-next {
  background-image: url('../public/images/left-arrow.webp');
  width: 26px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: 26px 56px;
}
.edouard-section .edouard-slider .slick-slider .slick-prev {
  left: -18px;
  z-index: 1;
}
.edouard-section .edouard-slider .slick-slider .slick-next {
  background-image: url('../public/images/right-arrow.webp');
  right: -5px;
}
.edouard-section .edouard-slider .slick-slider .slick-prev:before,
.edouard-section .edouard-slider .slick-slider .slick-next:before {
  display: none;
}
.edouard-section .edouard-content-slider .edouard-content {
  margin-top: 5px;
}
.buttons-wrap {
  display: flex;
  gap: 20px;
}
.nav-align {
  text-align: right;
}
.img-wrap {
  position: relative;
  width: 49vw;
  right: 80px;
}
.img-wrap img {
  width: 100%;
}
.section-wrap {
  overflow: hidden;
}
@media (max-width: 767px) {
  .img-wrap {
    width: 100%;
    right: auto;
    margin-bottom: 30px;
  }
}
@media (max-width: 1879px) {
  .faq-section .faq-content {
    max-width: 850px;
  }
  .banner-enter-text .text-hand-image img {
    max-width: 670px;
  }
  .banner-enter-text .text-hand-title img {
    max-width: 250px;
  }
  .morph-section .morph-title {
    left: 220px;
  }
}
@media (max-width: 1749px) {
  .faq-section .faq-cartoon-img-two img {
    max-width: 440px;
  }
  .faq-cartoon-img-one img {
    max-width: 450px;
  }
  .morph-section .morph-title {
    left: 0;
    max-width: 250px;
    top: 0;
  }
  .morph-section {
    margin-top: 275px;
  }
}
@media (max-width: 1599px) {
  .banner-section .banner-logo img {
    max-width: 410px;
    margin-right: auto;
  }
  .banner-section .banner-content {
    max-width: 600px;
  }
  .content-discriptions p,
  .edouard-section .edouard-content .edouard-content-dis,
  body {
    font-size: 20px;
  }
  .card-content .card-discriptions {
    font-size: 17px;
  }
  .edouard-section .edouard-content .edouard-content-dis {
    max-width: 450px;
  }
}
@media (max-width: 1399px) {
  .banner-section .banner-logo img {
    max-width: 310px;
  }
  .banner-section .banner-logo {
    margin-bottom: 20px;
  }
  .morph-section .morph-circle img {
    max-width: 160px;
  }
  .morph-section .morph-circle {
    right: 258px;
    top: 6px;
  }
  .banner-section .banner-cartoon-img img {
    max-width: 300px;
    margin-left: 30px;
  }
  .banner-enter-text .text-hand-image img {
    max-width: 470px;
  }
  .banner-enter-text .text-hand-title img {
    max-width: 200px;
  }
  .banner-enter-text .text-hand-title {
    right: 13px;
    max-width: 315px;
    top: 100px;
  }
  .morph-section {
    margin-top: 175px;
  }
  .morph-collection-detail .morph-collection-card img {
    max-width: 450px;
    margin-left: auto;
  }
  .morph-collection-detail .morph-collection-circle img {
    max-width: 57%;
    margin-left: auto;
  }
  .morph-collection-bgimg img {
    max-width: 750px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 340px;
  }
  .faq-cartoon-img-one img {
    max-width: 350px;
  }
  .faq-section .faq-content {
    max-width: 650px;
  }
}
@media (max-width: 1199px) {
  .morph-section .morph-circle {
    right: 195px;
  }
  .morph-section .morph-title {
    max-width: 215px;
  }
  .card-content .card-discriptions {
    font-size: 15px;
  }
  .edouard-section .edouard-slider .slick-slider .slick-slide img {
    width: 96%;
  }
  .toundery-section .toundery-title img {
    max-width: 300px;
  }
  .toundery-section .toundery-title .toundery-content {
    margin: 15px auto 0;
  }
  .faq-cartoon-img-one img {
    max-width: 290px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 240px;
  }
}
@media (max-width: 1023px) {
  .banner-enter-text .text-hand-title img {
    max-width: 173px;
  }
}
@media (max-width: 991px) {
  .dimond-section .edouard-section {
    position: relative;
    top: -15px;
  }
  .dimond-section .dimond-video iframe {
    width: 535px;
    height: 335px;
  }
  .content-discriptions p,
  .edouard-section .edouard-content .edouard-content-dis,
  body {
    font-size: 16px;
  }
  .collab-section .collab-title img {
    max-width: 220px;
  }
  .card-content .card-discriptions {
    padding: 15px;
  }
  .morph-collection-detail .morph-collection-card img {
    max-width: 350px;
  }
  .morph-collection-detail .morph-collection-circle {
    margin-top: 20px;
  }
  .morph-collection-detail .morph-collection-circle img {
    max-width: 46%;
  }
  .morph-collection-bgimg img {
    max-width: 515px;
  }
  .faq-cartoon-img-one img {
    max-width: 230px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 150px;
  }
  .toundery-section {
    padding: 30px 0;
  }
  .faq-section .faq-cartoon-img-two {
    bottom: -30px;
  }
  .dimond-section .dimond-video {
    max-width: 580px;
  }
  .dimond-section .dimond-video .dimond-video-title img {
    max-width: 200px;
  }
  .edouard-section .edouard-title {
    max-width: 360px;
  }
  .collab-section .collab-image img {
    max-width: 400px;
  }
  .banner-section .banner-content {
    max-width: 450px;
  }
  .banner-enter-text .text-hand-image img {
    max-width: 470px;
  }
  .banner-enter-text {
    top: 10px;
  }
  .banner-section .banner-container {
    padding: 0 20px;
  }
  .morph-section .morph-cards {
    right: -10px;
  }
}
@media (max-width: 767px) {
  .morph-section .morph-cards {
    right: 0;
    flex-wrap: wrap;
  }
  .banner-section .banner-logo img {
    max-width: 230px;
  }
  .banner-enter-text .text-hand-image img {
    max-width: 270px;
  }
  .banner-enter-text .text-hand-title img {
    max-width: 115px;
  }
  .banner-enter-text .text-hand-title {
    right: 13px;
    max-width: 315px;
    top: 22px;
  }
  .content-detail .banner-title img {
    max-width: 80%;
    margin: 0 auto;
  }
  .content-detail .banner-title h1 {
    font-size: 26px;
  }
  .morph-section .morph-title {
    top: -107px;
  }
  .morph-section .morph-circle {
    right: 0;
  }
  .morph-section .morph-cards .morph-card-holder:nth-child(2) {
    padding-top: 0;
  }
  .morph-collection-detail .morph-collection-card img {
    max-width: 250px;
  }
  .morph-collection-bgimg img {
    max-width: 365px;
  }
  .morph-collection-detail .morph-collection-circle img {
    max-width: 40%;
  }
  .dimond-section .dimond-video iframe {
    width: 435px;
    height: 235px;
  }
  .edouard-section .edouard-content .edouard-content-dis {
    margin-bottom: 30px;
  }
  .edouard-section .edouard-slider {
    max-width: 470px;
    margin: 0 auto;
  }
  .toundery-section .toundery-title img {
    max-width: 200px;
  }
  .toundery-section .toundery-title .toundery-heading img {
    max-width: 200px;
  }
  .list-detail .list-content {
    margin: 0 10px;
  }
  .faq-section .faq-title img {
    max-width: 110px;
  }
  .faq-section .faq-title {
    margin-bottom: 20px;
  }
  .faq-content .faq-content-detail h3 {
    font-size: 20px;
    letter-spacing: 1.2px;
  }
  .faq-cartoon-img-one img {
    max-width: 125px;
  }
  .faq-content .faq-content-detail {
    margin-bottom: 20px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 81px;
  }
  .faq-section .faq-cartoon-img-two {
    bottom: -20px;
  }
  .toundery-section {
    padding: 20px 0;
  }
  .toundery-section .toundery-list {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
@media (max-width: 574px) {
  .dimond-section .dimond-video iframe {
    width: 300px;
    height: 200px;
  }
  .collab-section .collab-image img {
    max-width: 250px;
  }
  .morph-collection-bgimg img {
    max-width: 300px;
  }
  .banner-section .banner-cartoon-img img {
    margin: 0;
    max-width: 100px;
  }
  .banner-section .banner-logo img {
    max-width: 190px;
  }
  .banner-section .head-icon img {
    max-width: 30px;
  }
  .morph-section {
    margin-top: 100px;
  }
  .morph-section .morph-circle img {
    max-width: 100px;
  }
  .morph-section .morph-title img {
    max-width: 150px;
  }
  .morph-section .morph-title {
    top: -80px;
  }
  .morph-collection-detail {
    right: 10px;
  }
  .morph-collection-detail .morph-collection-card img {
    max-width: 150px;
  }
  .dimond-section .dimond-video .dimond-video-title img {
    max-width: 160px;
  }
  .edouard-section .edouard-title {
    max-width: 250px;
  }
  .collab-section .collab-title img {
    max-width: 150px;
  }
  .edouard-section .edouard-slider .slick-slider .slick-prev,
  .edouard-section .edouard-slider .slick-slider .slick-next {
    width: 17px;
    height: 40px;
    background-size: 17px 40px;
  }
  .edouard-section .edouard-slider .slick-slider .slick-prev {
    left: -10px;
  }
  .collab-section .collab-title {
    top: -20px;
  }
  .collab-section {
    padding: 10px 0px 20px;
  }
  .toundery-section .toundery-list ul {
    flex-wrap: wrap;
  }
  .faq-cartoon-img-one img {
    max-width: 85px;
  }
  .footer-content .footer-icon img {
    max-width: 100px;
  }
  .footer-content ul li img {
    width: 20px;
  }
  .footer-content ul li {
    margin: 0 2px;
  }
  .faq-section .faq-cartoon-img-two img {
    max-width: 55px;
  }
  .faq-section .faq-cartoon-img-two {
    bottom: -19px;
  }
  .banner-section .banner-cartoon-img {
    top: 60px;
  }
}

@font-face {
  font-family: 'Helvetica Regular';
  src: url(fonts/Font/Helvetica-Font/Helvetica.ttf);
}
@font-face {
  font-family: 'Helvetica Bold';
  src: url(fonts/Font/Helvetica-Font/Helvetica-Bold.ttf);
}
@font-face {
  font-family: 'Technovier Regular';
  src: url(fonts/Font/technovier-techno-sans-family-2021-08-29-03-44-03-utc/Techovier.woff);
  src: url(fonts/Font/technovier-techno-sans-family-2021-08-29-03-44-03-utc/Techovier.woff2);
}
@font-face {
  font-family: 'Technovier Black';
  src: url(fonts/Font/technovier-techno-sans-family-2021-08-29-03-44-03-utc/Techovier\ Black.woff);
  src: url(fonts/Font/technovier-techno-sans-family-2021-08-29-03-44-03-utc/Techovier\ Black.woff2);
}
@font-face {
  font-family: 'Technovier Light';
  src: url(fonts/Font/technovier-techno-sans-family-2021-08-29-03-44-03-utc/Techovier\ Light.woff);
  src: url(fonts/Font/technovier-techno-sans-family-2021-08-29-03-44-03-utc/Techovier\ Light.woff2);
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url(fonts/Font/Helvetica-Font/Montserrat-Bold.ttf);
}
#section1 {
  background: url(../public/img/headbg.png) no-repeat;
  background-size: cover;
  color: white;
  padding-bottom: 30px;
}
header {
  padding: 10px;
  margin-bottom: 20px;
}
#section1 p {
  font-family: 'Helvetica Regular';
  font-size: 16px;
  font-weight: normal;
}
.topnav {
  overflow: hidden;
  background-color: black;
  position: absolute;
  z-index: 99;
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  font-family: 'Technovier Black';
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  display: block;
}
a:hover {
  color: #fff !important;
}
.fa.fa-bars {
  margin-top: 25px;
}
.humburger {
  text-align: center;
  display: block;
}

.topnav a.icon {
  background: black;
  display: block;
  margin-bottom: 70px;
}
#section1 .topnav p {
  font-family: 'Technovier Light';
  font-size: 16px;
  font-weight: normal;
}
.topnav .link2 {
  font-family: 'Technovier Bold';
  color: white;
  padding: 5px 0;
}
.active {
  background-color: #04aa6d;
  color: white;
}
.margin-left {
  margin-left: 240px !important;
}

/* #section1 .box1{
  background: url(../img/Rectangle10.png) no-repeat;
  padding: 71px 135px 71px 71px;
} */
#section1 .box1 {
  position: relative;
  /* background: url(../img/Group4.png) no-repeat; */
  padding: 71px 170px 71px 71px;
  /* background-size: cover; */
}
#section1 img.Keyframe1 {
  height: 400px;
  display: flex;
  margin: auto;
}
#section1 .web3 {
  font-family: 'Technovier Regular';
  border-bottom: 1px solid #c67abb;
  font-size: 16px;
  line-height: 40px;
  color: #fff;
}
.d-sm-none1 img {
  margin: auto;
}
#section2 {
  background: url(../public/img/section2.png) no-repeat;
  background-size: cover;
  color: white;
  padding-bottom: 70px;
}
#section2 h1 {
  color: #fff;
  font-family: 'Technovier Black';
  font-size: 40px;
}
#section2 h2 {
  font-family: 'Montserrat Bold';
  font-size: 20px;
}
body #number {
  font-family: 'Technovier Regular' !important;
  color: #606372;
  margin-bottom: 0;
}
#section2 p {
  font-family: 'Helvetica Regular';
  font-size: 16px;
  font-weight: normal;
  width: 70%;
}
#section2 .box2 {
  padding-left: 100px;
}
#section2 img.Keyframe1 {
  height: 500px;
  display: flex;
  margin: auto;
}
#section3 {
  background: url(../public/img/section3.png) no-repeat;
  background-size: cover;
  color: white;
  padding-top: 200px;
}
.img-abs {
  position: absolute;
}
#section3 .box3 {
  position: relative;
  /* background: url(../img/Group4.png) no-repeat; */
  padding: 71px 100px 71px 71px;
  background-size: cover;
}
#section3 p {
  font-family: 'Helvetica Regular';
  font-size: 16px;
  font-weight: normal;
  width: 75%;
}
#section3 h2 {
  font-family: 'Technovier Black';
  font-size: 24px;
  line-height: 50px;
}
#section3 .suppl {
  text-align: center;
  border: 1px solid #c67abb;
  border-radius: 50%;
  /* display: inline; */
  width: 70%;
  padding: 10px 10px;
}
#section3 .pp {
  width: 100%;
  line-height: 30px;
  border-bottom: 1px solid #c67abb;
}
#section3 img.Keyframe1 {
  height: 650px;
  display: flex;
  margin: auto;
}
#section4 {
  background: url(../public/img/section4.png) no-repeat;
  background-size: cover;
  color: white;
  padding-top: 50px;
  padding-bottom: 20px;
}
#section4 h2 {
  font-family: 'Technovier Black';
  font-size: 24px;
  line-height: 30px;
}
#section4 .line3-img {
  margin: 100px auto;
}
.box4 {
  background: url(../public/img/Group\ 6.png) no-repeat;
  /* position: relative; */
  background-size: initial;
  height: 45%;
}
.box4 img {
  margin-left: 144px;
  margin-top: 100px;
}
#section5 {
  background: url(../public/img/section5.png) no-repeat;
  background-size: cover;
  color: white;
  padding-top: 50px;
  padding-bottom: 20px;
  border-bottom: 3px solid #fff;
}
#section5 h2 {
  font-family: 'Helvetica Regular';
  font-size: 30px;
  margin-bottom: 20px;
}
#section5 .found-img {
  height: 300px;
}
#section6 {
  background: url(../public/img/footer.png) no-repeat;
  background-size: cover;
  color: white;
  padding-top: 50px;
  padding-bottom: 20px;
}
#section6 a.link {
  font-family: 'Helvetica Regular';
  color: white;
  font-size: 14px;
}
#section6 .found a {
  font-family: 'Technovier Black';
  color: white;
  font-size: 16px;
  text-decoration: none;
  line-height: 34px;
}
#section6 .found a::after {
  content: url('../public/img/Shape 45.png');
}

/* SLider */
.controls {
  width: 100%;
  max-width: 800px;
  margin-bottom: 8px;
}

.cbcontrol {
  font-size: 50px;
  float: left;
  color: white;
  /* width: 48%; */
  text-align: center;
  background-color: silver;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cbcontrol:hover {
  cursor: pointer;
}

.stage {
  float: left;
  height: 380px;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  z-index: 10;
  position: relative;
  -webkit-backface-visibility: hidden;
  padding-top: 30px;
}

.cbImage {
  width: 200px;
  height: 268px;
  float: left;
  margin: 25px 0px 25px 0px;
  color: white;
  /* box-shadow: 0px 5px 10px 0px #D6D6D6; */
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.cbImage img {
  width: 100%;
  height: 100%;
  /* margin-top:20px; */
}

.inactiveLeft {
  opacity: 0;
  -webkit-transform: perspective(500px) rotateY(-60deg);
  -o-transform: perspective(500px) rotateY(-60deg);
  transform: perspective(500px) skewY(25deg);
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  /* box-shadow: -12px 0px 20px 0px #d6d6d6; */
}

.inactiveRight {
  opacity: 0;
  -webkit-transform: perspective(500px) rotateY(-60deg);
  -o-transform: perspective(500px) rotateY(-60deg);
  transform: perspective(500px) skewY(25deg);
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  /* box-shadow: -12px 0px 20px 0px #d6d6d6; */
}

.immediate1 {
  opacity: 0.5;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-delay: 0.2s;
  margin-top: 0px;
  height: 270px;
  width: 100px;
}

.immediate2 {
  opacity: 0.5;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-delay: 0.2s;
  margin-top: 50px;
  width: 100px;
  /* height:310px; */
}

.active {
  z-index: 100;
  -webkit-transform: perspective(500px) rotateY(0deg);
  -o-transform: perspective(500px) rotateY(0deg);
  transform: perspective(500px) rotateY(0deg);
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.intro {
  color: #fff;
}

#section6 .contact-u {
  font-family: 'Technovier Black';
  font-size: 16px;
  color: #fff;
  line-height: 34px;
  text-decoration: none;
}
/* 768 */

@media (max-width: 1024px) {
  .margin-left {
    margin-left: 180px !important;
  }
  #section1 .box1 {
    padding: 50px 14px 71px 40px;
  }
  #section1 .img-fluid.img-abs {
    max-width: 45%;
    height: 400px;
  }
  #section3 .img-fluid.img-abs {
    max-width: 50%;
    height: 515px;
  }
  #section3 .box3 {
    padding: 18px 0 0 30px;
  }
  #section4 .box4 {
    height: auto;
  }
  #section2 .box2 {
    padding-left: 15px;
  }
  #section3 {
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .margin-left {
    margin-left: 100px !important;
  }
  #section3 .img-fluid.img-abs {
    max-width: 50%;
    height: 570px;
  }
  #section3 .box3 {
  }
  #section3 h2 {
    font-size: 20px;
  }
  #section3 p {
    font-size: 16px;
    width: 100%;
  }
  #section1 .web3 {
    font-family: 'Technovier Regular';
    border-bottom: 1px solid #c67abb;
    font-size: 13px;
    line-height: 30px;
  }
  #section3 img.Keyframe1 {
    height: 450px;
  }
  #section4 .line3-img {
    margin: 50px auto;
  }
  #section4 .box4 {
    height: 277px;
    background-size: cover;
  }
  .box4 img {
    margin-left: 115px;
    margin-top: 80px;
  }
  #section5 .found-img {
    height: 222px;
  }
}
@media (max-width: 767px) {
  .margin-left {
    margin-left: 0px !important;
  }
  #section4 img.Keyframe1,
  #section3 img.Keyframe1,
  #section2 img.Keyframe1,
  #section1 img.Keyframe1 {
    height: 225px;
    margin-bottom: 10px;
  }
  #section1 .img-fluid.img-abs {
    max-width: 94%;
    height: auto;
  }
  #section1 p {
    font-family: 'Helvetica Regular';
    font-size: 12px;
    font-weight: normal;
  }
  body #number {
    text-align: center;
  }
  #section2 p {
    width: 100%;
  }
  .d-sm-none1 {
    display: none;
  }
  .logo {
    margin-left: 5px;
    width: 50px;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 33% !important;
  }
  #section1-c .row.justify-content-around {
    justify-content: space-between !important;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 66% !important;
  }
  #section2 h1 {
    font-family: 'Technovier Black';
    font-size: 20px;
  }
  .flex-ss-row-reverse {
    flex-direction: column-reverse !important;
  }
  #section3 .img-fluid.img-abs {
    max-width: 94%;
    height: auto;
  }
  #section3 h2 {
    font-size: 16px;
    line-height: 30px;
  }
  #section3 p {
    font-size: 12px;
    margin-bottom: 5px;
  }
  #section3 .pp {
    margin-top: 12px !important;
    line-height: 20px;
  }
  #section3 .box3 {
    padding: 40px 18px 0 37px;
  }
  #section3 {
    padding-top: 50px;
  }
  #section4 h2 {
    font-size: 20px;
    text-align: center;
  }
  #section4 .box4 {
    height: auto;
    width: 100%;
    background-size: 100% 100%;
    text-align: center;
  }
  #section4 .box4 img {
    margin: 50px auto;
  }
  #section4 img.Keyframe1 {
    display: flex;
    margin: auto;
    margin-bottom: 10px;
  }
  #section5 h2 {
    text-align: center;
  }
  #section2 .box2 {
    padding-left: 90px;
  }

  #section6 a.link1 {
    font-size: 10px;
  }
  #section6 p.link1 {
    font-size: 10px;
    margin-bottom: 0px;
    margin-top: 6px;
  }
  .w8 {
    width: 60% !important;
  }
  .w4 {
    width: 40% !important;
  }
  .mobie-relaive {
    position: relative;
    bottom: 300px;
  }
  #section5 .box5 {
    margin-bottom: 5px;
  }
  .topnav a {
    padding: 10px 0px;
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  #section3 .box3 {
    padding: 18px 0px 0 6px;
  }
  #section3 .img-fluid.img-abs {
    max-width: 96%;
    height: auto;
  }
}

/* contact page */
img {
  display: inline;
}

.btn {
  /* background-color: red; For browsers that do not support gradients */
  /* background-image: linear-gradient(to right, #c04695 , #3965e4);*/
  background-color: #e9ae04;
  border-radius: 8px !important;
  font-family: arial;
  font-size: 19px;
  font-weight: 600;
}

.btn:hover {
  background-color: #f78c51;
}
body {
  /*background: url(../public/img/contact/contactbgpng.png) no-repeat;*/
  background-size: cover;
  background-color: #000000;
}
#section1-c {
  color: #fff;
}
.btn-cw {
  font-family: arial;
  font-size: 19px;
  font-weight: 600;
}
.btn-cw:hover {
  background-color: #f78c51;
}
.box1-c p {
  font-family: 'Poppins', sans-serif;
  font: 16px;
}
.box1-c h2 {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-weight: 600;
}
.hr {
  border-bottom: 2px solid #e9ae04;
}
.qty input {
  background: transparent;
  width: 70px;
  height: 34px;
  color: white;
  border: 2px solid gray;
  border-radius: 5px;
}
.box1 {
  padding: 45px;
}
.modal-content {
  border-radius: 30px !important;
}
.modal-header {
  border-bottom: none !important;
}
.modal-body h5 {
  font-family: 'Technovier Black';
  font-size: 20px;
}
.font-add {
  font-family: 'Technovier Black';
  font-size: 16px;
}
.loader-img {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-loader {
  width: 300px;
  height: 300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.loader-img img {
  background-color: #fff;
}
.product-button {
    margin: 40px 0 20px;
}
.product-button button.btn.btn-primary {
    min-width: 200px;
    padding: 10px 10px;
}
.modal-title-md {
    text-align: center;
    justify-content: center;
    width: 100%;
    color: #000;
    font-size: 30px;
    margin: 30px 0 20px 0;
}
.modal-title-md + button.btn-close {
    position: absolute;
    right: 30px;
    top: 26px;
    color: #000;
}
.modal-title-md span {
    max-width: 300px;
    display: inline-block;
    margin: auto;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 14rem auto !important;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader h1 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 10em;
  color: rgba(255, 255, 255, 0.1);
  background-image: url(https://image.ibb.co/ciSeac/image.png);
  background-repeat: repeat-x;
  -webkit-background-clip: text;
  animation: animate 15s linear infinite;
}

@keyframes animate {
  0% {
    background-position: left 0px top 80px;
  }
  40% {
    background-position: left 800px top -50px;
  }
  80% {
    background-position: left 1800px top -50px;
  }
  100% {
    background-position: left 2400px top 80px;
  }
}
